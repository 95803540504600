import React, { useState, useEffect } from 'react';
import Slider from 'react-slick'
import { Link, useParams } from 'react-router-dom';
import OfferModal from "../components/OfferModal"; 
import axios from 'axios';
import { useAuth } from "../context/AuthContext";
import config from '../config';

    const fetchContentApi = async () => {
      try {
        const response = await axios.get(config.apiUrl+'get-content/PricePlanPage');
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
function Price_plan() {

    const [content, setContent] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const contentData = await fetchContentApi();
            if (contentData && contentData.data && contentData.data.length > 0) {
                setContent(contentData.data);
            }
        };

        fetchData();
    },[])

    return (
        <div>
            <section className="banner inn">
                <div className="container">
                    <div className="bann-slid wow fadeInRightBig" data-wow-duration="2s">
                        <div>
                            <div className="row align-items-center">
                                <div className="col-md-5 col-lg-5 col-12">
                                    <div className="bann-txt wow fadeInLeftBig" data-wow-duration="2s">
                                        <h2>{content[0]?.title}</h2>
                                        {renderHTML(content[0]?.details)}
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-12">
                                    <div className="banner-img">
                                        <img src={`${config.adminbaseUrl}assets/images/${content[0]?.image}`} alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="head-3">
                        <h3>AFRZON</h3>
                    </div>
                </div>
            </section>
            <section className="terms">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            <div className="term-txt">
                                <h4>{content[1]?.title}</h4>
                                {renderHTML(content[1]?.details)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Price_plan
