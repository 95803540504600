import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

const ProductSearch = () => {
  // State to store search query, products, and loading state
  const [searchQuery, setSearchQuery] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to fetch products from the API
  const fetchProducts = async (query) => {
    if (!query) return; // Don't fetch if query is empty

    setLoading(true);

    try {
      // Replace this with your actual API URL
      const response = await axios.get(config.apiUrl + 'get-post', {
        params: { search: query },
      });

      setProducts(response.data); // Assuming the API returns products matching the search query
      console.log(products);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle change in search query
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Call API whenever the search query changes
    fetchProducts(query);
  };

  return (

    <div className="col-md-4 col-lg-6 col-sm-3 col-xs-12">
      <div className="input-btn">
        <form>
          <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder="Search anything..." />
          <button type='submit'><i className="fas fa-search" /></button>
          {/* Loading State */}
          {/* {loading && <p>Loading...</p>} */}

          {/* Display products */}
          {searchQuery !== '' && products.length !== 0 ?
            <ul className="searchkey_wrpr">
              {
                products.code == 200 ? (
                  products.data.map((product) => (
                    <li key={product.id}>
                      <Link to={`/post-add/${product.id}`} onClick={() => { setSearchQuery('') }}>
                        <div>
                          <img src={`${product.image}`} alt="" />
                        </div>
                        <div>
                          <span className="prod_title">{product.post_title}</span>
                          <span className="prod_prc">£{product.price}</span>
                        </div>
                      </Link>
                    </li>
                  ))
                ) : (
                  ''
                )
              }
            </ul>
            : false
          }
        </form>
      </div>
    </div>
  );
};

export default ProductSearch;