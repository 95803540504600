import React, { useState, useEffect } from 'react';
import Slider from 'react-slick'
import { Link, useParams } from 'react-router-dom';
import OfferModal from "../components/OfferModal"; 
import axios from 'axios';
import { useAuth } from "../context/AuthContext";
import config from '../config';

    const fetchContentApi = async () => {
      try {
        const response = await axios.get(config.apiUrl+'get-content/FAQPage');
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const fetchFaqApi = async () => {
      try {
        const response = await axios.get(config.apiUrl+'get-faq');
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });


function Faq() {
    const [content, setContent] = useState([]);
    const [faqs, setFaq] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const contentData = await fetchContentApi();
            const faqData = await fetchFaqApi();
            if (contentData && contentData.data && contentData.data.length > 0) {
                setContent(contentData.data);
            }
            if (faqData && faqData.data && faqData.data.length > 0) {
                setFaq(faqData.data);
            }
        };

        fetchData();
    },[])



    return (
        <div>
            <section className="banner inn">
                <div className="container">
                    <div className="bann-slid wow fadeInRightBig" data-wow-duration="2s">
                        <div>
                            <div className="row align-items-center">
                                <div className="col-md-5 col-lg-5 col-12">
                                    <div className="bann-txt wow fadeInLeftBig" data-wow-duration="2s">
                                        <h2>{content[0]?.title}</h2>
                                        {renderHTML(content[0]?.details)}
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-12">
                                    <div className="banner-img">
                                        <img src={`${config.adminbaseUrl}assets/images/${content[0]?.image}`} alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="head-3">
                        <h3>AFRZON</h3>
                    </div>
                </div>
            </section>
            <section className=" travel-ins-page-sec pt-8 pb-8">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="gorilla-content privacy-content ">
                                <div className="accordion privacy-accr" id="accordionExample">
                                {faqs.length > 0 ? (
                                    faqs.map((faq,index)=>(

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={`heading${index}`}>
                                            <button className={`accordion-button ${index == 0 ? '' : 'collapsed' }`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={`${index == 0 ? 'true' : 'false' }`} aria-controls={`collapse${index}`}>
                                                Sed do eiusmod tempor incididunt ut labore et.
                                            </button>
                                        </h2>
                                        <div id={`collapse${index}`} className={`accordion-collapse collapse ${index == 0 ? 'show' : '' }`} aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                            <div className="accordion-body gorrila-page-txt   ">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto.</p>
                                            </div>
                                        </div>
                                    </div>


                                        
                                    ))) : (
                                    <div className="loader-in">Loading..</div>
                                )}
                                    
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Faq
