import React, { useState, useEffect } from 'react';
import Slider from 'react-slick'
import { Link, useParams } from 'react-router-dom';
import OfferModal from "../components/OfferModal"; 
import axios from 'axios';
import { useAuth } from "../context/AuthContext";
import config from '../config';

    const fetchContentApi = async () => {
      try {
        const response = await axios.get(config.apiUrl+'get-content/BlogPage');
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const fetchBlogDetailApi = async (id) => {
      try {
        const response = await axios.get(config.apiUrl+'get-blog-detail/'+id);
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const fetchBlogApi = async () => {
      try {
        const response = await axios.get(config.apiUrl+'get-blogs');
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

function Blog_detail() {

    const [content, setContent] = useState([]);
    const [blog, setBlogDetail] = useState([]);
    const [blogs, setBlog] = useState([]);
    const {id} = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const contentData = await fetchContentApi();
            const blogDetail = await fetchBlogDetailApi(id);
            const blogsData = await fetchBlogApi();
            if (contentData && contentData.data && contentData.data.length > 0) {
                setContent(contentData.data);
            }
            if (blogDetail && blogDetail.data) {
                setBlogDetail(blogDetail.data);
            }
            if (blogsData && blogsData.data && blogsData.data.length > 0) {
                setBlog(blogsData.data);
            }
        };

        fetchData();
    },[])


    return (
        <div>
            <section className="banner inn">
                <div className="container">
                    <div className="bann-slid wow fadeInRightBig" data-wow-duration="2s">
                        <div>
                            <div className="row align-items-center">
                                <div className="col-md-5 col-lg-5 col-12">
                                    <div className="bann-txt wow fadeInLeftBig" data-wow-duration="2s">
                                        <h2>{content[0]?.title}</h2>
                                        {renderHTML(content[0]?.details)}
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-12">
                                    <div className="banner-img">
                                        <img src={`${config.adminbaseUrl}assets/images/${content[0]?.image}`} alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="head-3">
                        <h3>AFRZON</h3>
                    </div>
                </div>
            </section>
            <section className="blog-detail-sec sec">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
                            <div className="blog-detail-card-main">
                                <div className="blog-detail-card">
                                    <div className="blog-detail-card-img">
                                        <img src={`${config.adminbaseUrl}assets/images/${blog.photo}`} alt="img" />
                                    </div>
                                    <div className="blog-detail-card-txt-main">
                                        <div className="blog-detail-card-txt">
                                            <div className="blog-detail-card-heading-user">
                                                <div className="blog-detail-card-heading">
                                                    <h4>{blog.title}</h4>
                                                </div>
                                                
                                            </div>
                                            {renderHTML(blog.details)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="blog-detail-search-recent-tags-main">
                            
                                    <div className="blog-detail-search-main">
                                        <div className="blog-detail-search">
                                            <input type="search" name="bd-search" id="bd-search" placeholder="Enter Search Keywords" />
                                        </div>
                                        <div className="blog-detail-search-icon">
                                            <button type="submit">
                                                <i className="fal fa-search" />
                                            </button>
                                        </div>
                                    </div>
                                
                                <div className="blog-detail-recent-main">
                                    <div className="blog-detail-recent">
                                        <h5>Recent Blog pOSTS</h5>
                                    </div>
                                    {blogs.length > 0 ? (
                                        blogs.map((blog,index)=>(
                                            <div className="blog-list-card-main">
                                                <div className="blog-list-card">
                                                    <div className="blog-list-card-img">
                                                        <Link to={`/blog-detail/${blog.id}`}>
                                                            <img src={`${config.adminbaseUrl}assets/images/${blog.photo}`} alt="img" />
                                                        </Link>
                                                    </div>
                                                    <div className="blog-list-card-txt-main">
                                                        <div className="blog-list-card-txt">
                                                            <Link to={`/blog-detail/${blog.id}`}>
                                                                <h5>{blog.title}</h5>
                                                            </Link>
                                                        </div>
                                                        <div className="blog-list-card-user-icon-main">
                                                            <ul>
                                                                <li>
                                                                    <Link to={`/blog-detail/${blog.id}`}>
                                                                        <i className="fa-regular fa-user" />
                                                                    </Link>
                                                                    <p>By Admin</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))) : (
                                            <div className="loader-in">Loading..</div>
                                    )}
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Blog_detail
