import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';
import config from '../config';

const fetchCategoryData = async () => {
    try {
        const response = await axios.get(config.apiUrl + 'get-categories-data');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const fetchPostData = async (url) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const fetchBannersApi = async () => {
    try {
        const response = await axios.get(config.apiUrl + 'get-banners');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const fetchContentApi = async () => {
    try {
        const response = await axios.get(config.apiUrl + 'get-content/Home Page');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const fetchCategoryLinks = async () => {
    try {
        const response = await axios.get(config.apiUrl + 'get-category-link-data');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
export default function Home() {


    const [content, setContent] = useState([]);
    const [category, setCategory] = useState([]);
    const [post, setPost] = useState([]);
    const [banners, setBanners] = useState([]);
    const [recentpost, setRecentPost] = useState([]);
    // const [category, setCategory] = useState([]);
    const [links, setCategoryLinks] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const contentData = await fetchContentApi();
            const categoryData = await fetchCategoryData();
            const postData = await fetchPostData(config.apiUrl + 'get-feature-post?limit=6&featured=1');
            const recentpostData = await fetchPostData(config.apiUrl + 'get-post?limit=4');
            const bannersData = await fetchBannersApi();
            if (contentData && contentData.data && contentData.data.length > 0) {
                setContent(contentData.data);
            }

            if (categoryData && categoryData.data && categoryData.data.length > 0) {
                setCategory(categoryData.data);
            }
            if (postData && postData.data && postData.data.length > 0) {
                setPost(postData.data);
            }
            if (recentpostData && recentpostData.data && recentpostData.data.length > 0) {
                setRecentPost(recentpostData.data);
            }
            if (bannersData && bannersData.data && bannersData.data.length > 0) {
                setBanners(bannersData);
            }
        };
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const categoryData = await fetchCategoryData();
            if (categoryData && categoryData.data && categoryData.data.length > 0) {
                setCategory(categoryData.data);
            }
            const categoryList = await fetchCategoryLinks();
            if (categoryList && categoryList.data && categoryList.data.length > 0) {
                setCategoryLinks(categoryList.data);
            }
        };
        fetchData();
    }, [])
    // useEffect(() => {

    //     function timerStart() {

    //         expiringDate = new Date();
    //         expiringDate.setDate(expiringDate.getDate() + 11);
    //         intervalId = setInterval(tick, 1000);
    //     }

    //     function calculateRemainingTime() {
    //         let currentDate = new Date();
    //         let remainingTime = expiringDate.getTime() - currentDate.getTime();
    //         if (remainingTime < 0) return null;
    //         let dateObject = {};
    //         dateObject.days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    //         dateObject.hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //         dateObject.minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    //         dateObject.seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
    //         return dateObject;
    //     }

    //     function refreshTimer(dateObject) {

    //         if (!dateObject) {
    //             daysId.textContent = 0;
    //             hoursId.textContent = 0;
    //             minutesId.textContent = 0;
    //             secondsId.textContent = 0;
    //             clearInterval(intervalId);
    //         }

    //         daysId.textContent = dateObject.days;
    //         hoursId.textContent = dateObject.hours;
    //         minutesId.textContent = dateObject.minutes;
    //         secondsId.textContent = dateObject.seconds;

    //     }

    //     function tick() {

    //         let dateObject = calculateRemainingTime();
    //         refreshTimer(dateObject);

    //     }

    //     let expiringDate;
    //     let intervalId;

    //     let daysId = document.getElementById('days');
    //     let hoursId = document.getElementById('hours');
    //     let minutesId = document.getElementById('minutes');
    //     let secondsId = document.getElementById('seconds');

    //     timerStart()
    // })


    // var banner = {
    //     dots: true,
    //     arrows: false,
    //     rtl: false,
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    // };
    // var categories = {
    //     dots: true,
    //     arrows: false,
    //     rtl: false,
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: 8,
    //     slidesToScroll: 1
    // };

    const [hoveredIndex, setHoveredIndex] = useState(null);
    return (
        <div>
            {/* <section className="banner">
                <div className="container">
                    <div className="bann-slid wow fadeInRightBig" data-wow-duration="2s">
                        <Slider {...banner}>
                            {banners.code == 200 ? (
                                banners.data.map((banner) => (
                                    <div>
                                        <div className="row align-items-center">
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <div className="bann-txt wow fadeInLeftBig" data-wow-duration="2s">
                                                    <h3>{banner.title}</h3>
                                                    <h2>{banner.title2}</h2>
                                                    <p>{banner.details}</p>
                                                    <a href="https://afrzon-backend.demo-customproject.com/" className="btn-1"> Post AN ADD </a>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-8 col-12">
                                                <div className="banner-img">
                                                    <img src={`${config.adminbaseUrl}assets/images/${banner.photo}`} alt />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="loader-in">Loading banners...</div>
                            )}
                        </Slider>
                    </div>
                    <div className="head-3">
                        <h3>AFRZON</h3>
                    </div>
                </div>
            </section>
            <section className="categories">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-12">
                            <div className="gate-txt wow fadeInDown" data-wow-duration="2s">
                                <h3>CATEGORIES</h3>
                            </div>
                            <div className="cate-slid">
                                {category.length > 0 ? (
                                    <Slider {...categories}>
                                        {category.map((categorys, index) => (
                                            <div key={index}>
                                                <div className="cate-main-bx">
                                                    <Link to={`/products?category=${categorys.id}`}>
                                                        <img
                                                            src={`${config.adminbaseUrl}assets/images/${categorys.photo}`}
                                                            alt={categorys.name}
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src = `https://placehold.co/50x50/EEE/31343C?text=${categorys.name}`;
                                                            }}
                                                        />
                                                        <h5>{categorys.name}</h5>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                ) : (
                                    <div className="loader-in">Loading...</div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </section> */}
            <section className="Despired-car">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-8 col-md-8 col-12">
                            <div className="des-txt wow fadeInLeftBig" data-wow-duration="2s">
                                <h4>FIND YOUR DESIRED CARS</h4>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12 text-end wow fadeInRightBig" data-wow-duration="2s">
                            <Link to="/products" className="btn-1">MORE DETAIL</Link>
                        </div>
                        {post.length > 0 ? (
                            post.map((posts) => (
                                <div className="col col-md-2 wow fadeInLeft" data-wow-duration="2s">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to={`post-add/${posts.id}`}>
                                                <img
                                                    src={posts.image}
                                                    alt="Post"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = `https://placehold.co/50x50/EEE/31343C?text=${posts.post_title}`;
                                                    }}
                                                />
                                                {
                                                    posts.featured == 1 ? <img src="images/new.png" className="FeaturedPost" alt /> : ''
                                                }

                                            </Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>{posts.post_title}<span>{posts.type} - {posts.category}</span></h5>
                                            <ul>
                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to={`post-add/${posts.id}`} className="btn-1">View Post</Link>
                                        </div>
                                    </div>
                                </div>
                            ))) : (
                            <div className="loader-in">Loading..</div>
                        )} */}
                        <div className="row mt-5">
                            <div className="col-lg-3 col-md-4 col-12 wow fadeInRight" data-wow-duration="2s">
                                {/* <div className="car-main-bsx">
                                    <div className="car-tas">
                                        <h6>{content[0]?.title}</h6>
                                        {renderHTML(content[0]?.details)}

                                    </div>
                                    <div className="car-tas-img">
                                        <img src={`${config.adminbaseUrl}assets/images/${content[0]?.image}`} alt />
                                    </div>
                                    <div className="tas-txt-bac">
                                        <h3>AFRZON</h3>
                                    </div>
                                </div> */}
                                <div className="cate_box_new">
                                    {/* {links.length > 0 ? (
                                        links.map((link) => (
                                            <div className="nav-underlist">
                                                <h5>{link.parent} <i class="fas fa-chevron-right"></i></h5>
                                                <div className='nav-underlist_listt'>
                                                    {link.childs.map((item, index) => (
                                                        <Link to={`/products?category=${item.id}`}>{item.name}</Link>
                                                    ))}
                                                </div>
                                            </div>
                                        ))) : (
                                        <div className="loader-in">Loading..</div>
                                    )} */}
                                    <div class="cate_box_new">
                                        <div class="nav-underlist" onMouseEnter={() => setHoveredIndex(1)} onMouseLeave={() => setHoveredIndex(null)}>
                                            <h5><img src='images/property-icon.png' />Property / Land <i class="fa-solid fa-chevron-right"></i></h5>
                                            {/* <div className='nav-underlist_listt show'> */}
                                            <div className={`nav-underlist_listt ${hoveredIndex === 1 ? "show" : ""}`}>
                                                <a href="/products?category=88">To Rent</a>
                                                <a href="/products?category=87">Property Wanted</a>
                                                <a href="/products?category=86">Parking &amp; Garage</a>
                                                <a href="/products?category=85">Joint Venture</a>
                                                <a href="/products?category=84">International</a>
                                                <a href="/products?category=83">Holiday Rentals</a>
                                                <a href="/products?category=82">For Sale</a>
                                                <a href="/products?category=81">Deverloper Wanted</a>
                                                <a href="/products?category=80">Commercial</a>
                                            </div>
                                        </div>
                                        <div class="nav-underlist" onMouseEnter={() => setHoveredIndex(2)} onMouseLeave={() => setHoveredIndex(null)}>
                                            <h5><img src='images/services-icon.png' /> Services <i class="fa-solid fa-chevron-right"></i></h5>
                                            <div className={`nav-underlist_listt ${hoveredIndex === 2 ? "show" : ""}`}>
                                                <a href="/products?category=118">Vets</a>
                                                <a href="/products?category=117">Travel Agents</a>
                                                <a href="/products?category=116">Transport</a>
                                                <a href="/products?category=115">Training</a>
                                                <a href="/products?category=114">Tradesmen &amp; Construction</a>
                                                <a href="/products?category=113">Tour Operators</a>
                                                <a href="/products?category=112">Tilers</a>
                                                <a href="/products?category=111">Tailor / Fashion Designers</a>
                                                <a href="/products?category=110">SPA</a>
                                                <a href="/products?category=109">Property &amp; Maintenance</a>
                                            </div>
                                        </div>
                                        <div class="nav-underlist" onMouseEnter={() => setHoveredIndex(3)} onMouseLeave={() => setHoveredIndex(null)}>
                                            <h5><img src='images/vehicles-icon.png' /> Cars &amp; Vehicles <i class="fa-solid fa-chevron-right"></i></h5>
                                            <div className={`nav-underlist_listt ${hoveredIndex === 3 ? "show" : ""}`}>
                                                <a href="/products?category=20">Wanted</a>
                                                <a href="/products?category=19">Vans</a>
                                                <a href="/products?category=16">Trucks</a>
                                                <a href="/products?category=14">Plant &amp; Tractors</a>
                                                <a href="/products?category=13">Parts</a>
                                                <a href="/products?category=12">Other Vehicles</a>
                                                <a href="/products?category=10">Motorbikes &amp; Scooters</a>
                                                <a href="/products?category=6">Cars</a>
                                                <a href="/products?category=2">Accessories</a>
                                            </div>
                                        </div>
                                        <div class="nav-underlist" onMouseEnter={() => setHoveredIndex(4)} onMouseLeave={() => setHoveredIndex(null)}>
                                            <h5><img src='images/jobs-icon.png' /> Jobs <i class="fa-solid fa-chevron-right"></i></h5>
                                            <div className={`nav-underlist_listt ${hoveredIndex === 4 ? "show" : ""}`}>
                                                <a href="/products?category=72">Transport, Logistics &amp; Delivery</a>
                                                <a href="/products?category=71">Teaching &amp; Education</a>
                                                <a href="/products?category=70">Sport, Fitness &amp; Personal Trainer</a>
                                                <a href="/products?category=69">Security</a>
                                                <a href="/products?category=68">Sales</a>
                                                <a href="/products?category=67">Recruitment</a>
                                                <a href="/products?category=66">Purchasing &amp; Procurement</a>
                                                <a href="/products?category=65">Performing Arts</a>
                                                <a href="/products?category=64">Other</a>
                                                <a href="/products?category=63">Media, Digital &amp; Creative</a>
                                            </div>
                                        </div>
                                        <div class="nav-underlist" onMouseEnter={() => setHoveredIndex(5)} onMouseLeave={() => setHoveredIndex(null)}>
                                            <h5><img src='images/forsale-icon.png' /> For Sale <i class="fa-solid fa-chevron-right"></i></h5>
                                            <div className={`nav-underlist_listt ${hoveredIndex === 5 ? "show" : ""}`}>
                                                <a href="/products?category=43">Video Games &amp; Consoles</a>
                                                <a href="/products?category=42">TV, DVD, Blu-Ray &amp; Videos</a>
                                                <a href="/products?category=41">Tickets</a>
                                                <a href="/products?category=40">Swap Shop</a>
                                                <a href="/products?category=39">Stuff Wanted</a>
                                                <a href="/products?category=38">Sports, Leisure &amp; Travel</a>
                                                <a href="/products?category=37">Phones, Mobile Phones &amp; Telecoms</a>
                                                <a href="/products?category=36">Other Goods</a>
                                                <a href="/products?category=35">Office Furniture &amp; Equipment</a>
                                                <a href="/products?category=34">Musical Instruments &amp; DJ Equipment</a>
                                            </div>
                                        </div>
                                        <div class="nav-underlist" onMouseEnter={() => setHoveredIndex(6)} onMouseLeave={() => setHoveredIndex(null)}>
                                            <h5><img src='images/others-icon.png' /> Others <i class="fa-solid fa-chevron-right"></i></h5>
                                            <div className={`nav-underlist_listt ${hoveredIndex === 6 ? "show" : ""}`}>
                                                <a href="/products?category=79">Food Bank</a>
                                                <a href="/products?category=78">Electronics</a>
                                                <a href="/products?category=77">Books</a>
                                                <a href="/products?category=76">Arts &amp; Paintings</a>
                                                <a href="/products?category=75">Antiques</a>
                                                <a href="/products?category=74">Animals &amp; Pets</a>
                                                <a href="/products?category=73">Agricultural Products</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8 col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="new-good">
                                            <h4>Trending ads</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 text-end">
                                        <Link to="/products" className="btn-1">MORE PRODUCTS</Link>
                                    </div>
                                    {recentpost.length > 0 ? (
                                        recentpost.map((postd) => (
                                            <div className="col-lg-3">
                                                <div className="main-car-bx">
                                                    <div className="car-img">
                                                        <Link to={`post-add/${postd.id}`} >
                                                            <img
                                                                src={postd.image}
                                                                alt="Post"
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = `https://placehold.co/50x50/EEE/31343C?text=${postd.post_title}`;
                                                                }}
                                                            />
                                                            {postd.featured == 1 ? <img src="images/new.png" className="FeaturedPost" alt /> : ''
                                                            }
                                                        </Link>
                                                    </div>
                                                    <div className="car-txt">
                                                        <h5>{postd.post_title}<span>{postd.type} - {postd.category}</span></h5>
                                                        <ul>
                                                            <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                            <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                            <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                            <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                            <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                        </ul>
                                                        <div className="check">
                                                            <i className="fas fa-check" />
                                                            <h4>AVAILABLE</h4>
                                                        </div>
                                                        <Link to={`post-add/${postd.id}`} className="btn-1">View Post</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))) : (
                                        <div className="loader-in">Loading..</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >





            {/* <section className="accessories-sec all-section" >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                            <div className="accessories-txt-main wow fadeInLeft" data-wow-duration="2s">
                                <div className="accessories-txt" style={{
                                    backgroundImage: content[2]?.image ? `url(${config.adminbaseUrl}assets/images/${content[2].image})` : 'none',
                                }}>
                                    <h3>{content[2]?.title}</h3>
                                    {renderHTML(content[2]?.details)}
                                    <div className="accessories-txt-bottom">
                                        <ul>
                                            <li>
                                                <img src="images/accessories-bo1.png" alt="img" />
                                                <h5>
                                                    Keyboard
                                                </h5>
                                            </li>
                                            <li>
                                                <img src="images/accessories-bo2.png" alt="img" />
                                                <h5>
                                                    Surface Pen
                                                </h5>
                                            </li>
                                            <li>
                                                <img src="images/accessories-bo3.png" alt="img" />
                                                <h5>
                                                    Mices
                                                </h5>
                                            </li>
                                            <li>
                                                <img src="images/accessories-bo4.png" alt="img" />
                                                <h5>
                                                    Headephones
                                                </h5>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accessories-card-main wow fadeInRight" data-wow-duration="2s">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                                        <div className="accessories-card wow fadeInLeft" data-wow-duration="2s">
                                            <div className="accessories-card-txt-main">
                                                <div className="accessories-card-txt">
                                                    <h5>Products Name Here</h5>
                                                    <p>
                                                        Discount Up To 30%
                                                    </p>
                                                </div>
                                                <div className="accessories-card-btn">
                                                    <Link to="" className="btn-1">
                                                        Get A Quote
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="accessories-card-img">
                                                <img src="images/accessories-card1.png" alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                                        <div className="accessories-card wow fadeInRight" data-wow-duration="2s">
                                            <div className="accessories-card-txt-main">
                                                <div className="accessories-card-txt">
                                                    <h5>Products Name Here</h5>
                                                    <p>
                                                        Discount Up To 30%
                                                    </p>
                                                </div>
                                                <div className="accessories-card-btn">
                                                    <Link to="" className="btn-1">
                                                        Get A Quote
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="accessories-card-img">
                                                <img src="images/accessories-card2.png" alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                                        <div className="accessories-card">
                                            <div className="accessories-card-txt-main">
                                                <div className="accessories-card-txt">
                                                    <h5>Products Name Here</h5>
                                                    <p>
                                                        Discount Up To 30%
                                                    </p>
                                                </div>
                                                <div className="accessories-card-btn">
                                                    <Link to="" className="btn-1">
                                                        Get A Quote
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="accessories-card-img">
                                                <img src="images/accessories-card3.png" alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <div className="accessories-product-main wow fadeInLeft" data-wow-duration="2s">
                                <div className="accessories-product-img">
                                    <img src="images/accessories-product1.jpg" alt="img" />
                                </div>
                                <div className="accessories-product-txt">
                                    <h5>Products Name Here</h5>
                                    <p>Cars Model</p>
                                    <div className="accessories-star">
                                        <ul>
                                            <li>
                                                <Link to="">
                                                    <i className="fas fa-star" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="">
                                                    <i className="fas fa-star" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="">
                                                    <i className="fas fa-star" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="">
                                                    <i className="fas fa-star" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="">
                                                    <i className="fas fa-star" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="accessories-status">
                                        <ul>
                                            <li>
                                                <i className="fal fa-check" />
                                            </li>
                                            <li>
                                                <div className="accessories-status-txt"><h5>Available</h5></div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="accessories-btn">
                                        <Link to="" className="btn-1">
                                            Get A Quote
                                        </Link>
                                    </div>
                                    <div className="accessories-bottom">
                                        <h6>SKU: <span>30876</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* 
            <section className="Despired-car">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            <div className="des-txt wow fadeInLeftBig" data-wow-duration="2s">
                                <h4>FIND YOUR DREAM HOME</h4>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12 text-end wow fadeInRightBig" data-wow-duration="2s">
                            <Link to="/products" className="btn-1">MORE DETAIL</Link>
                        </div>
                        {post.length > 0 ? (
                            post.map((posts) => (
                                <div className="col col-md-2 wow fadeInLeft" data-wow-duration="2s">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to={`post-add/${posts.id}`}>
                                                <img
                                                    src={posts.image}
                                                    alt="Post"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = `https://placehold.co/50x50/EEE/31343C?text=${posts.post_title}`;
                                                    }}
                                                />
                                                {
                                                    posts.featured == 1 ? <img src="images/new.png" className="FeaturedPost" alt /> : ''
                                                }

                                            </Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>{posts.post_title}<span>{posts.type} - {posts.category}</span></h5>
                                            <ul>
                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                <li><Link to=""><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to={`post-add/${posts.id}`} className="btn-1">View Post</Link>
                                        </div>
                                    </div>
                                </div>
                            ))) : (
                            <div className="loader-in">Loading..</div>
                        )}



                    </div>
                </div>
            </section> */}


            {/* <section className="unlock" style={{
                backgroundImage: content[3]?.image ? `url(${config.adminbaseUrl}assets/images/${content[3].image})` : 'none',
            }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-lg-7 col-12"></div>
                        <div className="col-md-5 col-lg-5 col-12">
                            <div className="unlock-txt-box wow fadeInRightBig" data-wow-duration="2s">
                                <h4>{content[3]?.title}</h4>
                                {renderHTML(content[3]?.details)}
                                <a href={`${config.adminbaseUrl}user/login`} className="btn-1">Add A Post</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="perfect wow fadeInDownBig" data-wow-duration="2s">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            <div className="des-txt">
                                <h4>THE PERFECT FUSION OF HEALTH AND BEAUTY</h4>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12 text-end">
                            <Link to="" className="btn-1">MORE DETAIL</Link>
                        </div>
                        <div className="col">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <img src="images/pro-1.png" alt />
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <img src="images/pro-2.png" alt />
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <img src="images/pro-3.png" alt />
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <img src="images/pro-4.png" alt />
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <img src="images/pro-5.png" alt />
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </div >
    )
}
