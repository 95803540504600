import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSettings } from '../context/SettingsContext';
import ProductSearch from '../components/ProductSearch';
import config from '../config';
import axios from 'axios';





export default function Header() {


    const { settings, loading, error } = useSettings();

    const [isOpen, setIsOpen] = useState(false);



    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('sidenav-open');
        } else {
            document.body.classList.remove('sidenav-open');
        }
        return () => document.body.classList.remove('sidenav-open');
    }, [isOpen]);



    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleMouseEnter = () => setIsMenuOpen(true);
    const handleMouseLeave = () => setIsMenuOpen(false);

    // console.log(links);

    return (
        <div>
            <header>
                <div className="topSec wow fadeInLeft" data-wow-duration="2s">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-sm-3 col-xs-12 col-lg-6">
                                <div className="logo-img">
                                    <Link to="/"><img src={settings?.logo} alt="img" /></Link>
                                </div>
                            </div>
                            <div className="col-xs-8 col-sm-8 col-md-6 col-lg-6">
                                <div className="ankar">
                                    <ul>
                                        {/* <li>
                                            <a href={`${config.adminbaseUrl}`}>
                                                <i className="far fa-signs-post" />

                                                <h5>Add a Post </h5>
                                            </a>
                                        </li> */}
                                        <li>
                                            <a href={`${config.adminbaseUrl}`}>
                                                <i className="far fa-user" />
                                                <h5>SIGN UP</h5>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menuSec wow fadeInRight" data-wow-duration="2s">
                    <div className="container">
                        <div className="row">
                            <div className='col-lg-3'>
                                <div className="ankar">
                                    <ul>
                                        <li>
                                            <a href={`${config.adminbaseUrl}`}>
                                                <i className="far fa-signs-post" />

                                                <h5>Add a Post </h5>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* <div className="col-md-3 col-sm-6 col-xs-6">
                                <div className="header-category">
                                    <ul>
                                        <li className="pr_mega" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <Link to="javscript:;">CATEGORIES
                                                {
                                                    isMenuOpen === true ?
                                                        <i className="fas fa-chevron-up" />
                                                        : <i className="fas fa-chevron-down" />
                                                }

                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            <ProductSearch />
                            <div className="col-lg-3">
                                <div className="nav-head">
                                    <span onClick={openNav}>
                                        <i className="fa-solid fa-bars" />
                                    </span>
                                    <div className="sidenav" style={{ transform: isOpen ? 'translate(0px, 0px)' : 'translate(110%, 0px)' }}>
                                        <Link to="javascript:void(0)" className="closebtn" onClick={closeNav}>×</Link>
                                        <Link to="/" onClick={closeNav}>HOME</Link>
                                        <Link to="/products" onClick={closeNav}>PRODUCTS</Link>
                                        <Link to={`${config.adminbaseUrl}`} onClick={closeNav}>ADVERTISEMENT</Link>
                                        <Link to="/blogs" onClick={closeNav}>BLOGS</Link>
                                        <Link to="/faq" onClick={closeNav}>FAQ's</Link>
                                        <Link to="/contact" onClick={closeNav}> CONTACT US</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="prd_mega_menu" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ display: isMenuOpen ? 'block' : 'none' }}>
                    <div className='container'>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="abc_lis">
                                    <div className="row">
                                        {links.length > 0 ? (
                                            links.map((link) => (
                                                <div className="col-lg-3 col-md-3">
                                                    <div className="nav-underlist">
                                                        <h5>{link.parent}</h5>
                                                        {link.childs.map((item, index) => (
                                                            <Link to={`/products?category=${item.id}`}>{item.name}</Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))) : (
                                            <div className="loader-in">Loading..</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </header>
        </div >
    )
}
